<template>
  <div>
    <div class="profile">Price</div>
    <div class="profilePrice">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="Price Setting" name="first">
          <!-- <div class="priceMen">
                        <h3>Member messages</h3>
                        <hr />
                      </div>
                      <div class="priceSelect">
                        <h4>Price / Message</h4>
                        <el-select v-model="state.messages">
                          <el-option label="1.5" value="value1" />
                          <el-option label="2.0" value="value2" />
                          <el-option label="2.5" value="value3" />
                          <el-option label="3.0" value="value4" />
                        </el-select>
                      </div> -->

          <!-- <div class="priceMen">
                        <h3>Stories Subscription</h3>
                        <hr />
                      </div>
                      <div class="priceSelect">
                        <h4>Price / Week</h4>
                        <el-select v-model="state.week">
                          <el-option label="3.00" value="value1" />
                          <el-option label="4.00" value="value2" />
                          <el-option label="5.00" value="value3" />
                          <el-option label="6.00" value="value4" />
                        </el-select>
                      </div> -->
          <div class="priceMen">
            <h3>Video Calls</h3>
            <!-- <hr /> -->
          </div>
          <div class="priceSelect">
            <h4>Price Minute</h4>
            <el-input-number
              v-model="subscription"
              class="mx-4"
              :min="0.1"
              :max="15"
              :precision="1"
              controls-position="right"
              @change="changeFreeList"
              :step="0.1"
            />
          </div>
          <div class="priceMen pc">
            <h3>Fixed Service Pricing (coming soon)</h3>
            <!-- <hr /> -->
          </div>
          <div class="Service pc">
            <h4>Service Rendered</h4>
            <h4>Price per service</h4>
          </div>
          <div class="pc">
            <el-input style="width: 300px" disabled />
            <el-input style="width: 150px; margin-left: 30px" disabled />
          </div>

          <!-- <div class="priceMen">
            <h3>Free two-way video chat</h3>
            <hr />
          </div> -->
          <!-- <div class="priceSelect">
            <h4>Free Minute</h4>
            <el-input-number
              v-model="freeOption"
              class="mx-4"
              :min="0"
              :precision="2"
              controls-position="right"
              @change="changeFreeList1"
            />
          </div> -->
        </el-tab-pane>
        <!-- <el-tab-pane label="Special Requests" name="second"
                      >Config</el-tab-pane
                    > -->
      </el-tabs>
    </div>
  </div>
</template>
<script setup name="Price">
import { reactive, ref, onMounted } from "vue";
import { getPrice, updataPrice } from "@/api/center.js";
import { ElMessage } from "element-plus";
const props = defineProps({
  consultantId: {
    type: Number || "",
    default: "",
  },
});
const activeName = ref("first");
const ConInformation = ref("");
const state = reactive({
  priceInfor: {},
});
const handleClick = (tab, event) => {
  console.log(tab, event, "pp");
};
onMounted(() => {
  console.log(props.consultantId, "consultantId");
  getPriceList();
});
const subscription = ref(3);
const freeOption = ref(4);

// const getInformation = () => {
//   getConInformation().then((res) => {
//     if (res.code == 200) {
//       ConInformation.value = res.data
//       getPriceList()
//     }
//   })
// }

const handleChange = (val) => {
  console.log(val);
};

const getPriceList = () => {
  getPrice().then((res) => {
    if (res.code == 200) {
      console.log(res, "查询价格");
      state.priceInfor = res.data;
      if (res.data.freeMinutes || res.data.perMinute) {
        freeOption.value = res.data.freeMinutes;
        subscription.value = res.data.perMinute;
      } else {
        freeOption.value = 3;
        subscription.value = 3;
      }
    }
  });
};

const changeFreeList = () => {
  if (state.priceInfor == null) {
    let parma = {
      consultantId: props.consultantId,
      perMinute: subscription.value,
    };
    updataPrice(parma).then((res) => {
      if (res.code == 200) {
        console.log(res, "价格");
        ElMessage({
          message: "Modified successfully",
          type: "success",
        });
        getPriceList();
      }
    });
  } else {
    let parma = {
      consultantId: props.consultantId,
      perMinute: subscription.value,
      id: state.priceInfor.id,
    };
    updataPrice(parma).then((res) => {
      if (res.code == 200) {
        console.log(res, "价格");
        ElMessage({
          message: "Modified successfully",
          type: "success",
        });
        getPriceList();
      }
    });
  }
};

const changeFreeList1 = () => {
  if (state.priceInfor == null) {
    let parma = {
      consultantId: props.consultantId,
      freeMinutes: freeOption.value,
    };
    updataPrice(parma).then((res) => {
      if (res.code == 200) {
        console.log(res, "价格");
        ElMessage({
          message: "Modified successfully",
          type: "success",
        });
        getPriceList();
      }
    });
  } else {
    let parma = {
      consultantId: props.consultantId,
      freeMinutes: freeOption.value,
      id: state.priceInfor.id,
    };
    updataPrice(parma).then((res) => {
      if (res.code == 200) {
        console.log(res, "价格");
        ElMessage({
          message: "Modified successfully",
          type: "success",
        });
        getPriceList();
      }
    });
  }
};

// const updataType = () => {
//   updataPrice().then((res) => {})
// }
</script>
<style lang="scss" scoped>

//移动端
@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }
}



.profile {
  line-height: 50px;
  border-bottom: 1px solid #c2bcbc;
  padding-left: 20px;
  color: #02758a;
}

.profilePrice {
  margin: auto;
  padding: 20px 30px;

  .el-tabs {
    --el-tabs-header-height: 56px;
    // margin-left: 100px;
  }

  .el-tabs__nav {
    display: flex;
    justify-content: space-between;
    // padding-left: 100px;
  }

  .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 200px;
  }

  .el-tab-pane {
    padding: 10px 15px;
  }

  .priceMen {
    align-items: center;
    display: flex;
    margin-bottom: 1rem;
    color: rgb(143, 119, 164);

    h3 {
      font-weight: 400;
      white-space: nowrap;
      padding-right: 20px;
    }

    hr {
      margin-top: 22px;
      padding-left: 4px;
      background: rgb(143, 119, 164);
      border: 0;
      height: 1px;
      width: 100%;
    }
  }

  .priceSelect {
    margin-top: 20px;
    margin-bottom: 30px;

    h4 {
      margin-bottom: 8px;
      color: rgb(143, 119, 164);
      font-size: 16px;
    }

    h5 {
      color: rgb(143, 119, 164);
      margin-top: 14px;
      margin-bottom: 15px;
    }
  }
  .Service {
    display: flex;
    margin-bottom: 10px;
    h4 {
      margin-right: 200px;
      margin-bottom: 8px;
      color: rgb(143, 119, 164);
      font-size: 16px;
    }
  }

  .priceLang {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
}
</style>
